
import { defineComponent, ref, inject } from "vue";
import axios from "@/api/axios";
import Modal from "@/components/UI/Modal";
import Toast from "@/components/UI/Toast";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "Lottery",
  setup(){
    const router = useRouter();
    const arr = [1,2,3,4,5,6,7,8];
    let i = 0;//转到哪个位置
    let count= 0;//转圈初始值
    const totalCount = 3;//转动的总圈数
    let speed = 300;//转圈速度，越大越慢
    const minSpeed = 300;
    let timer: any = null;
    let isClick = false;
    let index = 3;//指定转到哪个奖品
    const text = ref("谢谢惠顾");//奖品
    const activeNum = ref(0);
    const imgUrl = ref("https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/92897e5a4672e9411b49da2e993f64f8.png");
    const imgStyle = ref({
      width: "1rem",
      left: "1.46rem",
      top: "1.5rem",
    });
    const wechatShow = ref(false);
    const imgUrlArr = [
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/92897e5a4672e9411b49da2e993f64f8.png",
        text: "谢谢惠顾"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png",
        text: "100-200元随机红包"
      },
      {
        img:  "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png",
        text: "38.88-66.88元随机红包"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png",
        text: "1.68-6.88元随机红包"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/92897e5a4672e9411b49da2e993f64f8.png",
        text: "谢谢惠顾"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png",
        text: "66.88-88.88元随机红包"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png",
        text: "20.21-38.88元随机红包"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png",
        text: "6.88-20.21元随机红包"
      },
    ]
    const imgArr = [
        {
            img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/92897e5a4672e9411b49da2e993f64f8.png",
            width: "1rem",
            left: "1.46rem",
            top: "1.5rem",
        },
        {
            img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png",
            width: "0.7rem",
            left: "1.63rem",
            top: "1.4rem",
        }
    ]
    const dialogShow = ref(false);
    function roll(){
        //速度衰减
        speed -= 50;
        if(speed<=10){
            speed=10;
        }
        //每次调用都去掉全部active类名
        activeNum.value = 10;
        i++;
        //计算转圈次数
        if(i >= 8){
            i = 0;
            count++;
        }
        activeNum.value = i;
        //满足转圈数和指定位置就停止
        if (count >= totalCount && (i + 1) == index){
            clearTimeout(timer);
            speed = minSpeed;
            text.value = imgUrlArr[i].text;
            imgUrl.value = imgUrlArr[i].img;
            isClick = true;
            for (let j = 0; j < imgArr.length; j++) {
                if (imgArr[j].img == imgUrl.value) {
                  imgStyle.value = {
                    width: imgArr[j].width,
                    left: imgArr[j].left,
                    top: imgArr[j].top,
                  }
                }
            }
            setTimeout(function () {
                dialogShow.value = true;
            }, 1200)
        }else{
            timer = setTimeout(roll,speed);//不满足条件时调用定时器
            //最后一圈减速
            if(count >= totalCount-1 || speed <= 50){
                speed += 100;
            }
        }
    }


    function handleDialog(){
      dialogShow.value = false;
    }
    function handleLuckDraw() {
      const formData = new FormData();
      formData.append('id', '5');
      axios
        .post("/M/Event/WheelOfFortune", formData)
        .then((res) => {
          const data = res.data.obj;
          if (res.data.success) {
            index = data;
            roll();
            text.value = res.data.msg;
          } else {
            if (res.data.msg == '请先登录再执行操作') {
              Modal({
                title: "温馨提示",
                content: res.data.msg,
                onClose: () => {
                  router.push({
                    name: "Login",
                    query: {
                      redirect: router.currentRoute.value.fullPath,
                    },
                  });
                },
              });
            } else {
              Toast({
                title: res.data.msg,
                type: "error",
                duration: 2000
              });
            }
            // Toast({
            //   title: res.data.msg,
            //   type: "error",
            //   duration: 2000
            // });
            if(res.data.msg == "未关注"){
              wechatShow.value = true;
            }
          }
        })
        .catch(() => {
          // Toast({
          //     title: '未知错误，请联系客服',
          //     type: "success"
          // })
        });
      // index = Math.floor(Math.random()*arr.length+1);
      // roll();
      // text.value = '100元代金券';
    }

    function handleWechatShow(){
      wechatShow.value = false;
    }

    return {
      text,
      imgUrl,
      handleLuckDraw,
      imgStyle,
      activeNum,
      dialogShow,
      handleDialog,
      handleWechatShow,
      wechatShow
    }
  }
});
