<template>
    <div class="wheelOfFortune_middle">
      <ul class="lottery">
        <li :class="{'wheelOfFortune_li_cash': true, 'active': activeNum == 0}">
          <img
            src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/92897e5a4672e9411b49da2e993f64f8.png"
            alt="抢红包-谢谢惠顾"
          />
          <div>谢谢惠顾</div>
        </li>
        <li :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 1}">
          <img
            src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png"
            alt="抢红包-随机红包"
          />
          <div>
            <span>100-200元</span>
            <p>随机红包</p>
          </div>
        </li>
        <li :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 2}">
          <img
            src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png"
            alt="抢红包-随机红包"
          />
          <div>
            <span>38.88-66.88</span>
            <p>随机红包</p>
          </div>
        </li>
        <li :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 3}">
          <img
            src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png"
            alt="抢红包-随机红包"
          />
          <div>
            <span>1.68-6.88元</span>
            <p>随机红包</p>
          </div>
        </li>
        <li :class="{'wheelOfFortune_li_cash': true, 'active': activeNum == 4}">
          <img
            src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/92897e5a4672e9411b49da2e993f64f8.png"
            alt="抢红包-谢谢惠顾"
          />
          <div>谢谢惠顾</div>
        </li>
        <li :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 5}">
          <img
            src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png"
            alt="抢红包-随机红包"
          />
          <div>
            <span>66.88-88.88元</span>
            <p>随机红包</p>
          </div>
        </li>
        <li :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 6}">
          <img
            src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png"
            alt="抢红包-随机红包"
          />
          <div>
            <span>20.21-38.88</span>
            <p>随机红包</p>
          </div>
        </li>
        <li :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 7}">
          <img
            src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png"
            alt="抢红包-随机红包"
          />
          <div>
            <span>6.88-20.21元</span>
            <p>随机红包</p>
          </div>
        </li>
        <li class="wheelOfFortune_li_luckDraw" @click="handleLuckDraw">
<!--          <img-->
<!--            src="@/assets/img/whellOfFortune/luckDraw.png"-->
<!--            alt="加微信抢红包-马上抽奖"-->
<!--          />-->
          <div>点击领取</div>
        </li>
      </ul>
    </div>

</template>

<script lang="ts">
import { defineComponent, ref, inject } from "vue";
import axios from "@/api/axios";
import Modal from "@/components/UI/Modal";
import Toast from "@/components/UI/Toast";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "Lottery",
  setup(){
    const router = useRouter();
    const arr = [1,2,3,4,5,6,7,8];
    let i = 0;//转到哪个位置
    let count= 0;//转圈初始值
    const totalCount = 3;//转动的总圈数
    let speed = 300;//转圈速度，越大越慢
    const minSpeed = 300;
    let timer: any = null;
    let isClick = false;
    let index = 3;//指定转到哪个奖品
    const text = ref("谢谢惠顾");//奖品
    const activeNum = ref(0);
    const imgUrl = ref("https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/92897e5a4672e9411b49da2e993f64f8.png");
    const imgStyle = ref({
      width: "1rem",
      left: "1.46rem",
      top: "1.5rem",
    });
    const wechatShow = ref(false);
    const imgUrlArr = [
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/92897e5a4672e9411b49da2e993f64f8.png",
        text: "谢谢惠顾"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png",
        text: "100-200元随机红包"
      },
      {
        img:  "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png",
        text: "38.88-66.88元随机红包"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png",
        text: "1.68-6.88元随机红包"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/92897e5a4672e9411b49da2e993f64f8.png",
        text: "谢谢惠顾"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png",
        text: "66.88-88.88元随机红包"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png",
        text: "20.21-38.88元随机红包"
      },
      {
        img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png",
        text: "6.88-20.21元随机红包"
      },
    ]
    const imgArr = [
        {
            img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/92897e5a4672e9411b49da2e993f64f8.png",
            width: "1rem",
            left: "1.46rem",
            top: "1.5rem",
        },
        {
            img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/f5741742cc154db5eb7b46359991dd74.png",
            width: "0.7rem",
            left: "1.63rem",
            top: "1.4rem",
        }
    ]
    const dialogShow = ref(false);
    function roll(){
        //速度衰减
        speed -= 50;
        if(speed<=10){
            speed=10;
        }
        //每次调用都去掉全部active类名
        activeNum.value = 10;
        i++;
        //计算转圈次数
        if(i >= 8){
            i = 0;
            count++;
        }
        activeNum.value = i;
        //满足转圈数和指定位置就停止
        if (count >= totalCount && (i + 1) == index){
            clearTimeout(timer);
            speed = minSpeed;
            text.value = imgUrlArr[i].text;
            imgUrl.value = imgUrlArr[i].img;
            isClick = true;
            for (let j = 0; j < imgArr.length; j++) {
                if (imgArr[j].img == imgUrl.value) {
                  imgStyle.value = {
                    width: imgArr[j].width,
                    left: imgArr[j].left,
                    top: imgArr[j].top,
                  }
                }
            }
            setTimeout(function () {
                dialogShow.value = true;
            }, 1200)
        }else{
            timer = setTimeout(roll,speed);//不满足条件时调用定时器
            //最后一圈减速
            if(count >= totalCount-1 || speed <= 50){
                speed += 100;
            }
        }
    }


    function handleDialog(){
      dialogShow.value = false;
    }
    function handleLuckDraw() {
      const formData = new FormData();
      formData.append('id', '5');
      axios
        .post("/M/Event/WheelOfFortune", formData)
        .then((res) => {
          const data = res.data.obj;
          if (res.data.success) {
            index = data;
            roll();
            text.value = res.data.msg;
          } else {
            if (res.data.msg == '请先登录再执行操作') {
              Modal({
                title: "温馨提示",
                content: res.data.msg,
                onClose: () => {
                  router.push({
                    name: "Login",
                    query: {
                      redirect: router.currentRoute.value.fullPath,
                    },
                  });
                },
              });
            } else {
              Toast({
                title: res.data.msg,
                type: "error",
                duration: 2000
              });
            }
            // Toast({
            //   title: res.data.msg,
            //   type: "error",
            //   duration: 2000
            // });
            if(res.data.msg == "未关注"){
              wechatShow.value = true;
            }
          }
        })
        .catch(() => {
          // Toast({
          //     title: '未知错误，请联系客服',
          //     type: "success"
          // })
        });
      // index = Math.floor(Math.random()*arr.length+1);
      // roll();
      // text.value = '100元代金券';
    }

    function handleWechatShow(){
      wechatShow.value = false;
    }

    return {
      text,
      imgUrl,
      handleLuckDraw,
      imgStyle,
      activeNum,
      dialogShow,
      handleDialog,
      handleWechatShow,
      wechatShow
    }
  }
});
</script>

<style lang="scss" scoped>

.wheelOfFortune_middle {
  width: 261px;
  height: 221.5px;
  background: url("https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/164b93e238fe27514c540348b8cd6fa9.png") no-repeat;
  background-size: 100%;
  margin: 0 auto;
  margin-top: 20px;
  box-sizing: content-box;
}

.wheelOfFortune_middle ul {
  width: 100%;
  height: 100%;
  padding: 13px 12.5px;
  position: relative;
}

.wheelOfFortune_middle ul li {
  width: 76px;
  height: 63px;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  /*background: url("../assets/img/whellOfFortune/cashBtn.png") no-repeat;*/
  background-size: cover;
  box-sizing: border-box;
  float: left;
  margin: 1px;
}

.wheelOfFortune_middle ul li p {
  -webkit-transform-origin-x: 0;
  -webkit-transform: scale(0.8);
  width: 125%;
  line-height: 12.5px;
  font-size: 12px;
}

// .wheelOfFortune_li_cash {
//   padding-top: 5px;
// }

.wheelOfFortune_li_cash img {
  width: 27px;
  height: 27px;
  margin-top: 5px;
}

.wheelOfFortune_li_cash div {
  margin-top: 2px !important;
}

.wheelOfFortune_li_cash div,
.wheelOfFortune_li_redpacket div, {
  color: rgb(167, 34, 5);
  font-weight: bold;
  -webkit-transform-origin-x: 0;
  -webkit-transform: scale(0.8);
  width: 125%;
  line-height: 12.5px;
  font-size: 11px;
}

.wheelOfFortune_li_redpacket div{
  margin-top: -2px;
}

.wheelOfFortune_li_luckDraw {
  position: relative;
}

.wheelOfFortune_li_luckDraw div {
  position: absolute;
  font-size: 20px;
  color: rgb(233, 20, 12);
  font-weight: bold;
  width: 60%;
  top: 5%;
  left: 21%;
  line-height: 25px;
}

.wheelOfFortune_li_redpacket img {
  width: 27.5px;
  height: 25.5px;
  margin-top: 3px;
}


.wheelOfFortune_li_integral div {
  margin-top: 3px;
}

.wheelOfFortune_li_integral img {
  width: 31.5px;
}

.wheelOfFortune_middle li.active {
  border: 4px solid #45fb27;
}

.wheelOfFortune_bottom {
  margin-top: 20px;
  padding-bottom: 75px;
  background: rgb(221, 0, 36);
}

.wheelOfFortune_bottom_line {
  width: 300px;
  height: 5.5px;
  background: rgb(234, 33, 7);
  border-radius: 10px;
  border: 1px solid rgb(234, 104, 162);
  margin: 0 auto;
}

.wheelOfFortune_bottom_wrap {
  width: 280px;
  margin: 0 auto;
}

.wheelOfFortune_bottom_title {
  width: 100%;
  height: 20px;
  background: rgb(246, 98, 89);
  color: rgb(248, 222, 30);
  font-size: 15px;
  text-align: center;
}

.wheelOfFortune_bottom_content {
  width: 100%;
  padding: 10px 0;
  color: #fff;
  font-size: 14px;
}

.wheelOfFortune_bottom_img img {
  width: 100%;
}

.wheelOfFortune_middle ul li:nth-child(1) {
  position: absolute;
  display: block;
  left: 13px;
  top: 12.5px;
}

.wheelOfFortune_middle ul li:nth-child(2) {
  position: absolute;
  left: 91px;
  top: 12.5px;
}

.wheelOfFortune_middle ul li:nth-child(3) {
  position: absolute;
  left: 169px;
  top: 12.5px;
}

.wheelOfFortune_middle ul li:nth-child(4) {
  position: absolute;
  left: 169px;
  top: 77.5px;
}

.wheelOfFortune_middle ul li:nth-child(8) {
  position: absolute;
  left: 13px;
  top: 77.5px;
}

.wheelOfFortune_middle ul li:nth-child(7) {
  position: absolute;
  left: 13px;
  top: 142.5px;
}

.wheelOfFortune_middle ul li:nth-child(6) {
  position: absolute;
  left: 91px;
  top: 142.5px;
}

.wheelOfFortune_middle ul li:nth-child(5) {
  position: absolute;
  left: 169px;
  top: 142.5px;
}

.wheelOfFortune_middle ul li:nth-child(9) {
  position: absolute;
  left: 91px;
  top: 77.5px;
  img{
    max-width: 100%;
  }
}

.wheelOfFortune_bottom_img {
  position: fixed;
  bottom: 0;
  left: 0;
}


.wheelOfFortune_dialog {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.wheelOfFortune_dialog_content {
  width: 194px;
  height: 270px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -97px;
  margin-top: -175px;
  background: url("https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/52edac6478431e1c4650730c2e3c21a3.png") no-repeat;
  background-size: contain;
  border-radius: 10px;
}

.wheelOfFortune_dialog_yy,
.wheelOfFortune_wechat_yy {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.prize {
  position: absolute;
  width: 50px;
  left: 73px;
  top: 75px;
}

.wheelOfFortune_dialog_bottom {
  margin-top: 160px;
  text-align: center;
}

.wheelOfFortune_dialog_bottom p {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  padding: 0 10px;
}

.wheelOfFortune_dialog_bottom span {
  display: inline-block;
  width: 119px;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  margin-top: 15px;
  background: rgb(255, 242, 59);
  box-shadow: 0px 3px 0px rgba(255, 169, 57, 1);
  -moz-box-shadow: 0px 3px 0px rgba(255, 169, 57, 1);
  -webkit-box-shadow: 0px 3px 0px rgba(255, 169, 57, 1);
  background: linear-gradient(to bottom, rgb(255, 249, 69), rgb(255, 198, 0));
  background: -moz-linear-gradient(to bottom, rgb(255, 249, 69), rgb(255, 198, 0));
  background: -webkit-linear-gradient(to bottom, rgb(255, 249, 69), rgb(255, 198, 0));
  color: rgb(255, 78, 0);
  font-weight: bold;
  font-size: 12px;
}

.wheelOfFortune_dialog_span {
  display: inline-block;
  width: 50%;
  height: 20px;
  position: absolute;
  top: 117.5px;
  left: 50px;
  background: rgb(255, 63, 49);
}

.wheelOfFortune_wechat {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.wheelOfFortune_wechat_title {
  font-size: 20px;
  color: #333;
}

.wheelOfFortune_wechat_title p {
  text-align: center;
}

.wheelOfFortune_wechat_content {
  width: 80%;
  background: #fff;
  position: absolute;
  text-align: center;
  padding: 15px;
  left: 10%;
  top: 20%;
}

.wheelOfFortune_wechat_content img {
  width: 250px;
}

.wheelOfFortune_wechat_active {
  display: none;
}
</style>
